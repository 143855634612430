export const CoachingPro = {
  title: "Coaching PRO",
  link: "/coaching-pro",
  isRelative: true,
  items: [
    {
      name: "Coach",
      link: "/coaching-pro/coaching",
      isRelative: true,
    },
    { name: "App", link: "/coaching-pro/app", isRelative: true },
  ],
};

export const NavbarMenusOnMobile = [
  {
    id: 1,
    title: CoachingPro.title,
    link: CoachingPro.link,
    items: CoachingPro.items,
  },
];
