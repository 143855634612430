import React from "react";
import * as F from "./nutritionPlan.styled";
import Chip from "components/general/formComponents/chip/Chip";
// Images
import AccordianPng from "assets/images/pngs/Foodiary-Rezepte.png";
// import Video from "assets/images/new/FoodiaryCoachErstgesprach.mp4"
import ReactPlayer from "react-player";

const NutritionPlan = () => {
  return (
    <F.CoachingAccordianContainer>
      <F.TextContent>
        <Chip text="ERNÄHRUNGSPLAN" margin="0 0 30px 0" />
        <F.Title className="ct-headline foodiary-h2 color-dark text-4xl mt-8 mb-12">
          Ein professioneller Ernährungsplan hilft wirklich!
        </F.Title>
        <F.TitleMobile className="ct-headline foodiary-h2 color-dark text-4xl mt-8 mb-12">
          Ein eigener Ernährungsplan hilft wirklich!
        </F.TitleMobile>
        <F.Info className="ct-text-block color-paragraph text-base text-left">
          Unzählige Standard Diät- und Ernährungsprogramme versprechen den
          schnellen Erfolg, doch häufig wird man hier nur enttäuscht.
        </F.Info>
        <F.Info className="ct-text-block color-paragraph text-base text-left">
          Doch ein professionell erstellter Ernährungsplan hält, was er
          verspricht! Dieser wird optimal an deine bestehende Situation und
          deinen Alltag angepasst. Er ist flexibel, abwechslungsreich und
          dauerhaft umsetzbar. Er lässt keine Zwänge entstehen und zeigt dir
          eine Richtung auf, die Ernährung Stück für Stück umzustellen.
        </F.Info>
      </F.TextContent>
      <F.VisualContent>
        {/* <img src={AccordianPng} alt="plan" /> */}
        <ReactPlayer
          url={"https://foodiary.app/data/LP-Foodiary-App-Dashboard-2.mp4"}
          playing
          muted
          loop
        />
      </F.VisualContent>
    </F.CoachingAccordianContainer>
  );
};

export default NutritionPlan;
