import React, { useState } from "react";
import * as C from "./mealPlanTools.styled";

// Images
import FoodIcon from "assets/images/myCoaching/Icon-food.svg";

import Recipies from "assets/images/new/Foodiary-App-recipes.png";
import Calories from "assets/images/new/Foodiary-App-daily-calories.png";
import Wochenplanung from "assets/images/new/Foodiary-App-wochenplanung.png";

import RecipiesWithoutShadow from "assets/images/new/Foodiary-App-recipes-without-shadow.png";
import CaloriesWithoutShadow from "assets/images/new/Foodiary-App-daily-calories-without-shadow.png";
import WochenplanungWithoutShadow from "assets/images/new/Foodiary-App-wochenplanung-without-shadow.png";

const MealPlanTools = () => {
  const [activeCard, setActiveCard] = useState(1);
  const [image, setImage] = useState(Recipies);

  const onSelectCard = (id) => {
    setActiveCard(id);
    switch (id) {
      case 1:
        setImage(Recipies);
        break;
      case 2:
        setImage(Calories);
        break;
      case 3:
        setImage(Wochenplanung);
        break;
      default:
        setImage(Recipies);
        break;
    }
  };

  return (
    <C.MealPlanContainer>
      <C.Title className="ct-headline foodiary-h2 color-dark text-4xl uppercase font-semibold">
        <b>Starte deine Veränderung</b> <br />
        UND NUTZE SMARTE TOOLS FÜR DEINEN ERFOLG
      </C.Title>
      <C.MealPlanLower>
        <C.VisualContent>
          <img src={image} alt="Meal Plan" />
        </C.VisualContent>

        <C.CardBoxWrap>
          <C.CardBox
            className={activeCard === 1 && "active"}
            onClick={() => onSelectCard(1)}
          >
            <C.ContentWrap>
              <C.CardBoxIcon>
                <img src={FoodIcon} alt="Food Icon" />
              </C.CardBoxIcon>
              <C.CardBoxRight>
                <C.PlanTitle className="ct-text-block font-medium text-xl">
                  Rezeptﬁlter mit künstlicher Intelligenz
                </C.PlanTitle>
                <C.PlanText className="ct-text-block mt-8 color-paragraph text-base font-normal">
                  Dank künstlicher Intelligenz treffen wir eine Vorauswahl an
                  Rezepten, welche zu deiner Ernährungsform passen und
                  integrieren diese in deinen Tag.
                </C.PlanText>
              </C.CardBoxRight>
            </C.ContentWrap>
            {activeCard === 1 && <C.VisualContentInside>
              <img src={RecipiesWithoutShadow} alt="Meal Plan" />
            </C.VisualContentInside>}
          </C.CardBox>

          <C.CardBox
            className={activeCard === 2 && "active"}
            onClick={() => onSelectCard(2)}
          >
            <C.ContentWrap>
              <C.CardBoxIcon>
                <img src={FoodIcon} alt="Food Icon" />
              </C.CardBoxIcon>
              <C.CardBoxRight>
                <C.PlanTitle className="ct-text-block font-medium text-xl">
                  Dynamische Kalorienanpassung
                </C.PlanTitle>
                <C.PlanText className="ct-text-block mt-8 color-paragraph text-base font-normal">
                  Durch die dynamische Kalorienanpassung gehen wir auf
                  Veränderungen in deinem Stoffwechsel ein. Das ist für dich
                  wichtig, um langfristig dein Ziel zu erreichen.
                </C.PlanText>
              </C.CardBoxRight>
            </C.ContentWrap>
            {activeCard === 2 && <C.VisualContentInside>
              <img src={CaloriesWithoutShadow} alt="Meal Plan" />
            </C.VisualContentInside>}
          </C.CardBox>

          <C.CardBox
            className={activeCard === 3 && "active"}
            onClick={() => onSelectCard(3)}
          >
            <C.ContentWrap>
              <C.CardBoxIcon>
                <img src={FoodIcon} alt="Food Icon" />
              </C.CardBoxIcon>
              <C.CardBoxRight>
                <C.PlanTitle className="ct-text-block font-medium text-xl">
                  Wochenplanung
                </C.PlanTitle>
                <C.PlanText className="ct-text-block mt-8 color-paragraph text-base font-normal">
                  Deine Woche kannst du einfach vorplanen. So wählst du in
                  deinem Ernährungsplan die Rezepte aus, welche du gerne
                  zubereiten möchtest und zu deinen Makronährwerten passen.
                  Unser künstliche Intelligenz ﬁltert diese so, dass du nur die
                  Rezepte siehst, welche zu deinen Nährwerten passen.
                </C.PlanText>
              </C.CardBoxRight>
            </C.ContentWrap>
            {activeCard === 3 && <C.VisualContentInside>
              <img src={WochenplanungWithoutShadow} alt="Meal Plan" />
            </C.VisualContentInside>}
          </C.CardBox>
        </C.CardBoxWrap>
      </C.MealPlanLower>
    </C.MealPlanContainer>
  );
};

export default MealPlanTools;
