import React, { useState } from "react";
import * as C from "./navbarAccordians.styled";

import PlusIcon from "assets/images/myCoaching/Icon-plus.svg";
import CloseIcon from "assets/images/myCoaching/Icon-Close.svg";

const Question = ({
  title,
  items,
  link,
  id,
  onExpanded,
  activeQuestion,
  titleShouldHaveLink,
}) => {
  const [expanded, setExpanded] = useState(null);

  return (
    <C.AccordianBox className="question">
      <C.AccordianHeader>
        <C.AccordianTitle
          className="question-title"
          active={expanded === activeQuestion?.id}
          onClick={() => {
            if (titleShouldHaveLink) {
              window.open(link);
            } else {
              setExpanded(id);
              onExpanded();
            }
          }}
        >
          {title}
        </C.AccordianTitle>
        {expanded === activeQuestion?.id && (
          <C.AccordianButton
            className="btn"
            onClick={() => {
              setExpanded(null);
              onExpanded(null);
            }}
          >
            <img width="12px" src={CloseIcon} alt="close" />
          </C.AccordianButton>
        )}
        {expanded !== activeQuestion?.id && (
          <C.AccordianButton
            className="btn"
            onClick={() => {
              setExpanded(id);
              onExpanded(id);
            }}
          >
            <img width="14px" src={PlusIcon} alt="plus" />
          </C.AccordianButton>
        )}
      </C.AccordianHeader>
      {expanded === activeQuestion?.id && (
        <>
          {items.map((item, i) => (
            <C.MenuItem key={i}>
              <a
                href={item.link}
                target={item.newTab ? "_blank" : ""}
                rel="noreferrer"
              >
                {item.name}
              </a>
            </C.MenuItem>
          ))}
        </>
      )}
    </C.AccordianBox>
  );
};

export default Question;
