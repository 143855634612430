import React from "react";
import * as F from "./dailyRoutine.style";
import Chip from "components/general/formComponents/chip/Chip";

// Images
// import FoodiaryErnahrungsberatung from "assets/images/pngs/Foodiary-Ernahrungsberatung.png";
// import Video from "assets/images/functions/APP/Animation-Tagesablauf-white-grey.mp4";
import ReactPlayer from "react-player";

const DailyRoutine = () => {
  return (
    <F.CoachingWithMeContainer>
      <F.VisualContent>
        {/* <img src={FoodiaryErnahrungsberatung} alt="coach" /> */}
        <ReactPlayer url={"https://foodiaryproduction.s3.eu-central-1.amazonaws.com/Website_files/Animation-Tagesablauf-white-grey.mp4"} playing muted loop />
      </F.VisualContent>
      <F.ContentSection>
        <Chip text="TAGESABLÄUFE" margin="0 0 30px 0" />
        <F.Title className="ct-text-block font-bold color-dark text-left text-4xl">
          Flexibilität im Alltag
        </F.Title>
        <F.Description className="ct-text-block color-paragraph text-base text-left">
          Ganz gleich ob du in Schicht arbeitest, das Wochenende zum Ausschlafen
          nutzt oder sich deine Trainingstage verändern, dein Ernährungsplan
          passt sich deiner Woche an.
        </F.Description>
        <F.Description className="ct-text-block color-paragraph-alt text-base text-left mt-6">
          Lege jede Woche eine neue Routine fest und wir empfehlen dir daraufhin
          automatisch die geeigneten Rezeptvorschläge für deinen Tagesablauf.
        </F.Description>
      </F.ContentSection>
    </F.CoachingWithMeContainer>
  );
};

export default DailyRoutine;
